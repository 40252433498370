export function findAdressByZipCode({ zipCode } = {}) {
  return {
    type: 'geolocation/FIND_ADDRESS_BY_ZIPCODE',
    payload: { zipCode }
  }
}

export function updateAddress({ address, error } = {}) {
  return {
    type: 'geolocation/UPDATE_ADDRESS',
    payload: { address, error }
  }
}

export function findAdressByData({ address } = {}) {
  return {
    type: 'geolocation/FIND_ADDRESS_BY_DATA',
    payload: { address }
  }
}

export function updateGeolocation({ geolocation, error } = {}) {
  return {
    type: 'geolocation/UPDATE_GEOLOCATION',
    payload: { geolocation, error }
  }
}

export function updateAddressField({ field, value } = {}) {
  return {
    type: 'geolocation/UPDATE_ADDRESS_FIELD',
    payload: { field, value }
  }
}

export function getCities({ addressRegion } = {}) {
  return {
    type: 'geolocation/GET_CITIES',
    payload: { addressRegion }
  }
}

export function updateCities({ cities, error } = {}) {
  return {
    type: 'geolocation/UPDATE_CITIES',
    payload: { cities, error }
  }
}
