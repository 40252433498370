import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import DefaultLayout from '~/pages/_layouts/default'

export default function RouteWrapper({ component: Component, ...rest }) {
  const Layout = DefaultLayout

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  permission: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]).isRequired
}

RouteWrapper.defaultProps = {
  isPrivate: false,
  permission: false
}
