import produce from 'immer'

import * as ACTs from './actions'

export const Types = {}

Object.values(ACTs).forEach((fn) => {
  const type = fn().type

  Types[type.split('/').pop()] = type
})

const INITIAL_STATE = {
  loadings: {
    bookingVoucher: false
  },
  errors: {
    bookingVoucher: false
  }
}

export default function raffleReducers(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.BOOKING_VOUCHER: {
        draft.loadings.bookingVoucher = true
        draft.errors.bookingVoucher = false
        break
      }
      case Types.UPDATE_BOOKING_VOUCHER: {
        draft.loadings.bookingVoucher = false
        draft.errors.bookingVoucher = action.payload?.error || false
        break
      }
      default:
    }
  })
}
