import { createStore, applyMiddleware, compose } from 'redux'

export default (reducers, middlewares) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const enhancer =
    process.env.NODE_ENV !== 'production'
      ? compose(composeEnhancers(applyMiddleware(...middlewares)))
      : applyMiddleware(...middlewares)

  return createStore(reducers, enhancer)
}
