import React, { useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { Grid, Typography, Grow, Button } from '@material-ui/core'
import BrandLogo from '~/assets/images/logo/brands'
import googlePlayTag from '~/assets/images/icons/ds/google-play-tag.svg'
import appleStoreTag from '~/assets/images/icons/ds/store-apple-tag.svg'
import configs from '~/env.json'
import { useSelector } from 'react-redux'

import { htmlClean } from '~/helpers/strings'
import { BRANCH_TYPES } from '~/helpers/constants'

export default function ErrorCampaign() {
  const classes = useStyles()
  const theme = useTheme()

  const brandComponentsSettings = {
    BOT: {
      color: theme?.themeCustom?.COR_PRIMARIA,
      alt: 'Logo O Boticário',
      goToWebsiteButtonLabel: 'Acessar site O Boticário >',
      width: '196',
      height: '24'
    },
    QDB: {
      color: theme?.themeCustom?.COR_PRIMARIA,
      alt: 'Logo Quem Disse, Berenice?',
      goToWebsiteButtonLabel: 'Acessar site QDB >',
      width: '304',
      height: '48'
    },
    VULT: {
      color: theme?.themeCustom?.COR_PRIMARIA,
      alt: 'Logo Vult',
      goToWebsiteButtonLabel: 'Acessar site Vult >',
      width: '170',
      height: '48'
    },
    AUS: {
      color: theme?.themeCustom?.COR_PRIMARIA,
      alt: 'Logo Australian Gold',
      goToWebsiteButtonLabel: 'Acessar site Australian Gold >',
      width: '170',
      height: '110'
    }
  }

  const {
    campaignLockReasons,
    consumerMenu,
    layout: { CAMPANHA_ENCERRADA_TITULO, CAMPANHA_ENCERRADA_TEXTO } = {}
  } = useSelector((state) => state?.campaignReducers?.campaign)

  const branch = configs?.REACT_APP_BRANCH || 'BOT'

  useEffect(() => {
    document.querySelector('head title').text = 'Campanha não encontrada'
  }, [])

  const brandName = BRANCH_TYPES[branch]?.name ? BRANCH_TYPES[branch]?.name : 'O Boticário'
  const consumerMenuRules = consumerMenu?.find((rule) => rule?.item === 'REGULAMENTO')

  const defaultTextCampaign = 'Você pode acessar o site para conferir novidades e aproveitar as promoções.'

  return (
    <>
      <Grid
        container
        alignItems='center'
        alignContent='center'
        justify='center'
        direction='column'
        wrap='nowrap'
        className={classes.PAGE_CONTAINER}
      >
        <Grid item xs>
          <Grid container spacing={6} className={classes.TEXT_CONTAINER}>
            {CAMPANHA_ENCERRADA_TITULO && (
              <Grid item>
                <Grow in>
                  <Typography variant='h5' color='primary' align='left'>
                    {CAMPANHA_ENCERRADA_TITULO}
                  </Typography>
                </Grow>
              </Grid>
            )}
            {CAMPANHA_ENCERRADA_TEXTO && (
              <>
                <Grid item>
                  <Grow in>
                    <Typography
                      variant='body1'
                      color='primary'
                      align='left'
                      dangerouslySetInnerHTML={{
                        __html: htmlClean(CAMPANHA_ENCERRADA_TEXTO)
                      }}
                    />
                  </Grow>
                </Grid>
                <Grid item>
                  <Grow in>
                    <Typography variant='body1' color='primary' align='left'>
                      {defaultTextCampaign}
                    </Typography>
                  </Grow>
                </Grid>
              </>
            )}

            {!CAMPANHA_ENCERRADA_TITULO && !CAMPANHA_ENCERRADA_TEXTO && (
              <>
                <Grid item align='center'>
                  <Grid item className={classes.IMG_BRAND}>
                    <BrandLogo
                      color={brandComponentsSettings[branch].color}
                      width={brandComponentsSettings[branch].width}
                      height={brandComponentsSettings[branch].height}
                    />
                  </Grid>
                  <Grow in>
                    <Typography variant='h5' color='primary' align='center'>
                      {campaignLockReasons?.[0] || 'A campanha não foi encontrada'}
                    </Typography>
                  </Grow>
                </Grid>
                <Grid item>
                  <Grow in>
                    <Typography variant='body1' color='primary' align='center'>
                      {defaultTextCampaign}
                    </Typography>
                  </Grow>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs className={classes.BTN_CONTAINER}>
          <Grid container direction='column' wrap='nowrap' spacing={6}>
            {consumerMenuRules?.link && (
              <Grid item>
                <Grow in>
                  <Button fullWidth variant='contained' target='_blank' color='primary' href={consumerMenuRules?.link}>
                    Ver regulamento
                  </Button>
                </Grow>
              </Grid>
            )}
            <Grid item>
              <Grow in>
                <Button
                  fullWidth
                  variant='contained'
                  target='_blank'
                  color='primary'
                  href={configs.REACT_APP_URL.replace(/h?campanha/, 'www')}
                >
                  {brandComponentsSettings[branch].goToWebsiteButtonLabel}
                </Button>
              </Grow>
            </Grid>
          </Grid>
          {brandName === 'O Boticário' && (
            <Grid item className={classes.LINK_CONTAINER}>
              <a href='https://play.google.com/store/apps/details?id=com.boticario.mobile'>
                <img src={googlePlayTag} alt='Tag google play' />
              </a>
              <a href='https://apps.apple.com/br/app/o-botic%C3%A1rio/id978751089'>
                <img src={appleStoreTag} alt='tag apple store' />
              </a>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  PAGE_CONTAINER: {
    padding: theme.spacing(5, 5, 5, 5),
    marginTop: theme.spacing(11),
    minHeight: window.innerHeight
  },
  TEXT_CONTAINER: {
    maxWidth: 355
  },
  BTN_CONTAINER: {
    width: 325,
    marginTop: theme.spacing(7)
  },
  IMG_BRAND: {
    marginBottom: theme.spacing(7),
    '& > svg': {
      height: 40
    }
  },
  LINK_CONTAINER: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(7)
  }
}))
