import produce from 'immer'

import * as ACTs from './actions'

export const Types = {}

Object.values(ACTs).forEach((fn) => {
  const type = fn().type

  Types[type.split('/').pop()] = type
})

const INITIAL_STATE = {
  loadings: {
    findAdressByZipCode: false,
    findAdressByData: false,
    getCities: false
  },
  errors: {
    addressFind: false,
    getCities: false
  },
  geolocation: {},
  address: {},
  cities: []
}

export default function geolocationReducers(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.FIND_ADDRESS_BY_ZIPCODE: {
        draft.loadings.findAdressByZipCode = true
        draft.errors.addressFind = false
        break
      }
      case Types.UPDATE_ADDRESS: {
        draft.loadings.findAdressByZipCode = false
        draft.errors.addressFind = action.payload?.error || false

        draft.address = action.payload?.address || {}
        break
      }
      case Types.FIND_ADDRESS_BY_DATA: {
        draft.loadings.findAdressByData = true
        draft.errors.addressFind = false
        break
      }
      case Types.UPDATE_GEOLOCATION: {
        draft.loadings.findAdressByData = false
        draft.errors.addressFind = action.payload?.error || false

        draft.geolocation = action.payload?.geolocation || {}
        break
      }
      case Types.GET_CITIES: {
        draft.loadings.getCities = true
        draft.errors.getCities = false
        break
      }
      case Types.UPDATE_CITIES: {
        draft.loadings.getCities = false
        draft.errors.getCities = action.payload?.error || false

        draft.cities = action.payload?.cities || []
        break
      }
      case Types.UPDATE_ADDRESS_FIELD: {
        if (action?.payload?.field) {
          const nAddress = { ...state.address }

          if (nAddress[action?.payload?.field]) {
            delete nAddress[action?.payload?.field]
          }

          if (action?.payload?.value) {
            nAddress[action.payload.field] = action.payload.value
          }

          draft.address = nAddress
        }
        break
      }
      default:
    }
  })
}
