import { takeLatest, put, all, call } from 'redux-saga/effects'

import axios from 'axios'
import api from '~/services/api'

import { updateAddress, updateGeolocation, updateCities } from './actions'

export function* findAdressByZipCode({ payload }) {
  try {
    const { zipCode } = payload

    if (!zipCode) throw new Error('Ocorreu um erro ao processar a requisição')

    const url = `consumer/geolocation/findaddress?zipCode=${zipCode}`

    const response = yield call(api.get, url)

    if (response.status === 200) {
      if (response.data.success && response.data?.data && response.data.data.length > 0) {
        yield put(updateAddress({ address: response.data.data[0] }))
      } else {
        yield put(
          updateAddress({
            error: response?.data?.errors ? response?.data?.errors[0] : response.data.message
          })
        )
      }
    } else {
      yield put(updateAddress({ error: 'Ocorreu um erro ao processar a requisição' }))
    }
  } catch (error) {
    yield put(updateAddress({ error: error.message }))
  }
}

export function* findAdressByData({ payload }) {
  try {
    const { address } = payload
    const { addressLocality, addressRegion, streetAddress, number } = address

    if (!address || !addressLocality || !addressRegion || !streetAddress || !number)
      throw new Error('Ocorreu um erro ao processar a requisição')

    const addressRequire = { ...address }

    delete addressRequire?.district

    const urlParams = new URLSearchParams()

    Object.keys(addressRequire).forEach((e) => {
      urlParams.append(e, addressRequire[e])
    })

    const url = `/geolocation/findcoordinates?${urlParams.toString()}`

    const response = yield call(api.get, url)

    if (response.status === 200) {
      if (response.data.success && response.data?.data && response.data.data.length > 0) {
        yield put(updateGeolocation({ geolocation: response.data.data[0] }))
      } else {
        yield put(
          updateGeolocation({
            error: response?.data?.errors ? response?.data?.errors[0] : response.data.message
          })
        )
      }
    } else {
      yield put(
        updateGeolocation({
          error: 'Ocorreu um erro ao processar a requisição'
        })
      )
    }
  } catch (error) {
    yield put(updateGeolocation({ error: error.message }))
  }
}

export function* getCities({ payload }) {
  try {
    const { addressRegion } = payload

    if (!addressRegion) throw new Error('Ocorreu um erro ao processar a requisição')

    const url = `/states/${addressRegion}.json`

    const response = yield call(axios.get, url)

    if (response.status === 200) {
      if (response.data.length > 0) {
        yield put(updateCities({ cities: response.data }))
      } else {
        yield put(updateCities({ error: 'Nenhuma cidade encontrada' }))
      }
    } else {
      yield put(updateCities({ error: 'Ocorreu um erro ao processar a requisição' }))
    }
  } catch (error) {
    yield put(updateCities({ error: error.message }))
  }
}

export default all([
  takeLatest('geolocation/FIND_ADDRESS_BY_ZIPCODE', findAdressByZipCode),
  takeLatest('geolocation/FIND_ADDRESS_BY_DATA', findAdressByData),
  takeLatest('geolocation/GET_CITIES', getCities)
])
