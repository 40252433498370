import { takeLatest, delay, put, all, call } from 'redux-saga/effects'
import { toLocation } from '~/helpers/redirect'

import api from '~/services/api'
import newrelic from '~/services/newrelic'

import {
  updateStoresByText,
  updateStoreDates,
  updateStoreTimes,
  updateStoresNearby,
  updateBookingVoucher,
  updateReminder,
  pickStore,
  bookingVoucher as bookingVoucherAction
} from './actions'

import { updateVoucher } from '~/store/modules/voucher/actions'

export function* searchStoresByText({ payload }) {
  try {
    const { campaignId, consumerCpf, search } = payload

    if (!campaignId || !consumerCpf || !search) throw new Error('Ocorreu um erro ao processar a requisição')

    const urlParams = new URLSearchParams()

    urlParams.append('searchText', search)
    urlParams.append('consumerCpf', consumerCpf)

    const url = `/campaign/${campaignId}/stores/searchByText?${urlParams.toString()}`

    const response = yield call(api.get, url)

    if (response.status === 200) {
      if (response.data.success && response.data?.data && response.data.data.length > 0) {
        yield put(updateStoresByText({ stores: response.data.data }))
      } else {
        yield put(updateStoresByText({ error: !response?.data?.success }))
      }
    } else {
      yield put(
        updateStoresByText({
          error: 'Ocorreu um erro ao processar a requisição'
        })
      )
    }
  } catch (error) {
    yield put(updateStoresByText({ error: error.message }))
  }
}

export function* searchStoresNearby({ payload }) {
  try {
    const { campaignId, consumerCpf, geolocation } = payload
    const { lat, lng } = geolocation

    if (!campaignId || !consumerCpf || !lat || !lng) throw new Error('Ocorreu um erro ao processar a requisição')

    const urlParams = new URLSearchParams()

    urlParams.append('lat', lat)
    urlParams.append('lng', lng)
    urlParams.append('consumerCpf', consumerCpf)

    const url = `/campaign/${campaignId}/stores/nearby?${urlParams.toString()}`

    const response = yield call(api.get, url)

    if (response.status === 200) {
      if (!response?.data?.errors || response.data.errors.length === 0) {
        yield put(updateStoresNearby({ stores: response.data.data }))
      } else {
        yield put(
          updateStoresNearby({
            error: response?.data?.errors[0] || response.data.message
          })
        )
      }
    } else {
      yield put(
        updateStoresNearby({
          error: 'Ocorreu um erro ao processar a requisição'
        })
      )
    }
  } catch (error) {
    yield put(updateStoresNearby({ error: error.message }))
  }
}

export function* bookingVoucher({ payload }) {
  const {
    campaignId,
    consumerCpf,
    storeCode,
    voucherCode,
    batch,
    timeChosenRedemption,
    attempt,
    _redirect,
    _condition
  } = payload

  try {
    if (!campaignId || !consumerCpf || !voucherCode) throw new Error('Ocorreu um erro ao processar a requisição')
    if (storeCode && (!timeChosenRedemption || timeChosenRedemption.length < 1))
      throw new Error('Ocorreu um erro ao processar a requisição')

    const url = `/campaign/${campaignId}/consumer/${consumerCpf}/booking`

    const response = yield call(api.post, url, {
      batch,
      voucherCode,
      ...(storeCode ? { storeCode } : {}),
      ...(timeChosenRedemption.length ? { timeChosenRedemption } : {})
    })

    if (response.status === 200) {
      if (response.data.success && response.data?.data && response.data.data.length > 0) {
        const { storeCellPhone, storeCode, ...voucher } = response.data.data[0]

        yield put(updateVoucher({ voucher }))
        yield put(pickStore({ store: { storeCellPhone, storeCode } }))
        yield put(updateBookingVoucher())
        yield toLocation(_redirect, _condition, response.data?.data)
      } else {
        throw new Error(response?.data?.errors?.length ? response?.data?.errors[0] : response.data.message)
      }
    } else {
      throw new Error('Ocorreu um erro ao processar a requisição')
    }
  } catch (error) {
    if (attempt >= 3) {
      yield put(updateBookingVoucher({ error: error.message }))
    } else {
      yield delay(1000)
      yield put(bookingVoucherAction({ ...payload, ...{ attempt: attempt + 1 } }))
    }
  }
}

export function* getStoreDates({ payload }) {
  try {
    const { campaignId, storeCode } = payload

    if (!campaignId || !storeCode) throw new Error('Ocorreu um erro ao processar a requisição')

    const url = `/campaign/${campaignId}/stores/${storeCode}/redeemDays`

    const response = yield call(api.get, url)

    if (response.status === 200) {
      if (response.data.success && response.data?.data) {
        const dates = response.data.data
        if (dates.length === 0) {
          yield put(
            updateStoreDates({
              error:
                'Ops, alguém foi mais rápido e escolheu o último item do estoque disponível dessa loja. Você pode tentar novamente amanhã ou selecionar uma nova loja.'
            })
          )
        } else {
          yield put(updateStoreDates({ dates }))
        }
      } else {
        yield put(
          updateStoreDates({
            error: response?.data?.errors ? response?.data?.errors[0] : response.data.message
          })
        )
      }
    } else {
      yield put(updateStoreDates({ error: 'Ocorreu um erro ao processar a requisição' }))
    }
  } catch (error) {
    yield put(updateStoreDates({ error: error.message }))
  }
}

export function* getStoreTimes({ payload }) {
  try {
    const { campaignId, storeCode } = payload

    if (!campaignId || !storeCode) throw new Error('Ocorreu um erro ao processar a requisição')

    const url = `/campaign/${campaignId}/consumer/stores/${storeCode}/availability`

    const response = yield call(api.get, url)

    if (response.status === 200) {
      if (response.data.success && response.data?.data && response.data.data[0]?.availableTimes) {
        yield put(
          updateStoreTimes({
            availableTimes: response.data.data[0].availableTimes
          })
        )
      } else {
        yield put(
          updateStoreTimes({
            error: response?.data?.errors ? response?.data?.errors[0] : response.data.message
          })
        )
      }
    } else {
      yield put(updateStoreTimes({ error: 'Ocorreu um erro ao processar a requisição' }))
    }
  } catch (error) {
    yield put(updateStoreTimes({ error: error.message }))
  }
}

export function* storeReminder({ payload }) {
  let response

  try {
    const { campaignId, storeCode, consumerCpf } = payload

    if (!campaignId || !storeCode || !consumerCpf) throw new Error('Ocorreu um erro ao processar a requisição')

    const url = `/campaign/${campaignId}/store/${storeCode}/reminder`

    response = yield call(api.post, url, {
      consumerCpf: consumerCpf
    })

    if (response.status === 200) {
      if (response.data.success) {
        yield put(updateReminder())
      } else {
        throw new Error(response?.data?.errors[0] || response.data.message)
      }
    } else {
      throw new Error('Ocorreu um erro ao processar a requisição')
    }
  } catch (error) {
    newrelic.noticeError(error, { response: response?.data, payload })
    yield put(updateReminder({ error: error.message }))
  }
}

export default all([
  takeLatest('store/SEARCH_STORES_BY_TEXT', searchStoresByText),
  takeLatest('store/GET_STORE_DATES', getStoreDates),
  takeLatest('store/GET_STORE_TIMES', getStoreTimes),
  takeLatest('store/SEARCH_STORES_NEARBY', searchStoresNearby),
  takeLatest('store/BOOKING_VOUCHER', bookingVoucher),
  takeLatest('store/STORE_REMINDER', storeReminder)
])
