import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import FullPageLoader from '~/components/fullpage-loader/fullpage-loader'

export default function DefaultLayout({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.Wrapper}>
      <div className={classes.ContentContainer}>
        <React.Suspense fallback={<FullPageLoader />}>{children}</React.Suspense>
      </div>
    </div>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired
}

const useStyles = makeStyles((theme) => ({
  Wrapper: {
    minHeight: window.innerHeight,
    backgroundColor: theme.palette.background.default,
    overflowX: 'hidden'
  },
  ContentContainer: {
    display: 'flex',
    position: 'relative',
    minHeight: window.innerHeight
  }
}))
