import { takeLatest, delay, put, all, call } from 'redux-saga/effects'

import api from '~/services/api'

import { updateBookingVoucher, bookingVoucher as bookingVoucherAction } from './actions'
import { updateVoucher } from '~/store/modules/voucher/actions'

export function* bookingVoucher({ payload }) {
  const { campaignId, consumerCpf, batch, voucherCode, attempt } = payload

  try {
    if (!campaignId || !consumerCpf || !voucherCode || !batch)
      throw new Error('Ocorreu um erro ao processar a requisição')

    const url = `/campaign/${campaignId}/consumer/${consumerCpf}/booking`

    const response = yield call(api.post, url, {
      voucherCode,
      batch
    })

    if (response.status === 200) {
      if (response.data?.success && response.data?.data?.length) {
        yield put(updateVoucher({ voucher: response.data.data[0] }))
        yield put(updateBookingVoucher())
      } else {
        throw new Error(response?.data?.errors?.length ? response?.data?.errors[0] : response.data.message)
      }
    } else {
      throw new Error('Ocorreu um erro ao processar a requisição')
    }
  } catch (error) {
    if (attempt >= 3) {
      yield put(updateBookingVoucher({ error: error.message }))
    } else {
      yield delay(1000)
      yield put(bookingVoucherAction({ ...payload, ...{ attempt: attempt + 1 } }))
    }
  }
}

export default all([takeLatest('raffle/BOOKING_VOUCHER', bookingVoucher)])
