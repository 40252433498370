export function setResetDate({ cpf, campaignId } = {}) {
  return {
    type: 'resetDate/SET_RESET_DATE',
    payload: { cpf, campaignId }
  }
}

export function updateResetDate({ email, error } = {}) {
  return {
    type: 'resetDate/UPDATE_RESET_DATE',
    payload: { email, error }
  }
}

export function clearResetDate() {
  return {
    type: 'resetDate/CLEAR_RESET_DATE'
  }
}
