import React from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import Routes from '~/routes'
import { store, persistor, history } from '~/store'
import { IntlProvider } from 'react-intl'
import languageMessages from '~/assets/languages/pt-BR'
import { hot } from 'react-hot-loader/root'
import { ConfigCatProvider, PollingMode } from 'configcat-react'
import configs from '~/env.json'

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <IntlProvider messages={languageMessages} locale='pt-BR' defaultLocale='pt-BR'>
          <ConnectedRouter history={history}>
            <ConfigCatProvider sdkKey={configs?.REACT_APP_CONFIG_CAT} pollingMode={PollingMode.LazyLoad}>
              <Routes />
            </ConfigCatProvider>
          </ConnectedRouter>
        </IntlProvider>
      </PersistGate>
    </Provider>
  )
}

export default process.env.NODE_ENV !== 'production' ? hot(App) : App
