export function bookingVoucher({ campaignId, consumerCpf, voucherCode, batch, attempt = 1 } = {}) {
  return {
    type: 'raffle/BOOKING_VOUCHER',
    payload: { campaignId, consumerCpf, voucherCode, batch, attempt }
  }
}

export function updateBookingVoucher({ error } = {}) {
  return {
    type: 'raffle/UPDATE_BOOKING_VOUCHER',
    payload: { error }
  }
}
