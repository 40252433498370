import { createTheme } from '@material-ui/core/styles'

const createCustomTheme = ({
  primaryColor,
  secondaryColor,
  errorColor,
  warningColor,
  titleFonts,
  baseFont,
  ExternalFonts
}) =>
  createTheme({
    palette: {
      background: {
        paper: '#ffffff',
        default: '#F5F5F5'
      },
      primary: {
        main: primaryColor
      },
      secondary: {
        main: secondaryColor
      },
      error: {
        main: errorColor
      },
      warning: {
        main: warningColor
      },
      info: {
        main: primaryColor
      },
      success: {
        main: '#23B218'
      },
      'brand-primary': {
        main: primaryColor
      },
      'brand-secondary': {
        main: secondaryColor
      },
      'brand-tertiary': {
        main: '#EE3D96'
      },
      'neutral-ultra-dark': {
        main: '#000000'
      },
      'neutral-dark': {
        main: '#515151'
      },
      'neutral-medium': {
        main: '#878787'
      },
      'neutral-light': {
        main: '#F5F5F5'
      },
      'neutral-ultra-light': {
        main: '#FFFFFF'
      },
      'neutral-light-grey': {
        main: '#CCC'
      },
      'action-conversion-static': {
        light: '#8DC63F',
        main: '#8DC63F',
        dark: secondaryColor
      },
      'action-conversion-hover': {
        main: secondaryColor
      },
      'action-conversion-disabled': {
        main: '#D6D6D6'
      },
      'action-navigation-static': {
        light: primaryColor,
        main: primaryColor,
        dark: '#BE1B87'
      },
      'action-navigation-hover': {
        main: primaryColor
      },
      'action-navigation-disabled': {
        main: '#D6D6D6'
      },
      'action-contrast-static': {
        main: '#FFFFFF',
        contrastText: '#515151'
      },
      'action-contrast-hover': {
        main: '#2DD1FF'
      },
      'action-contrast-disabled': {
        main: '#ffffff3d',
        contrastText: '#D6D6D6'
      },
      'support-whatsapp': {
        main: '#128C7E'
      },
      'support-facebook': {
        main: '#3B5998'
      },
      'support-BOT': {
        main: '#6F967E',
        text: '#ffffff',
        name: 'Boticário'
      },
      'support-EUD': {
        main: '#472D75',
        text: '#ffffff',
        name: 'Eudora'
      },
      'support-QDB': {
        main: '#DB5958',
        text: '#ffffff',
        name: 'Quem Disse Berenice'
      },
      'support-BLZ': {
        main: '#6A1B60',
        text: '#ffffff',
        name: 'Beleza na Web'
      },
      'support-VULT': {
        main: '#000',
        text: '#AE332C',
        name: 'VULT'
      },
      contrastThreshold: 3,
      tonalOffset: 0.2
    },
    spacing: [0, 4, 8, 12, 16, 24, 32, 40, 48, 56, 64, 96, 150],
    breakpoints: {
      values: {
        xs: 0,
        sm: 320,
        md: 768,
        lg: 1440,
        xl: 1920,
        mobile: 320,
        tablet: 768,
        desktop: 1440
      }
    },
    typography: {
      fontFamily: baseFont,
      h1: {
        fontFamily: titleFonts,
        fontSize: 64,
        lineHeight: '72px',
        fontWeight: 400
      },
      h2: {
        fontFamily: titleFonts,
        fontSize: 48,
        lineHeight: '56px',
        fontWeight: 400
      },
      h3: {
        fontFamily: titleFonts,
        fontSize: 40,
        lineHeight: '48px',
        fontWeight: 400
      },
      h4: {
        fontFamily: titleFonts,
        fontSize: 32,
        lineHeight: '40px',
        fontWeight: 600
      },
      h5: {
        fontFamily: titleFonts,
        fontSize: 24,
        lineHeight: '32px',
        fontWeight: 400
      },
      h6: {
        fontFamily: titleFonts,
        fontSize: 20,
        lineHeight: '24px',
        fontWeight: 400
      },
      body1: {
        fontFamily: baseFont,
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 400
      },
      body2: {
        fontFamily: baseFont,
        fontStyle: 'normal',
        fontSize: 14,
        lineHeight: '24px',
        fontWeight: 400
      },
      subtitle1: {
        fontFamily: baseFont,
        fontStyle: 'normal',
        fontSize: 14,
        lineHeight: '24px',
        fontWeight: 400
      },
      subtitle2: {
        fontFamily: baseFont,
        fontStyle: 'normal',
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: 400
      },
      caption: {
        fontFamily: baseFont,
        fontStyle: 'normal',
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: 400
      },
      overline: {
        fontFamily: baseFont,
        fontStyle: 'normal',
        fontSize: 10,
        lineHeight: '16px',
        textTransform: 'uppercase',
        letterSpacing: '0.08em',
        fontWeight: 600
      }
    },
    overrides: {
      MuiAutocomplete: {
        inputRoot: {
          '&.MuiOutlinedInput-root.MuiInputBase-root': {
            padding: '0 9px',
            '& .MuiAutocomplete-input': {
              padding: '0 4px'
            }
          }
        }
      },
      MuiDrawer: {
        paper: {
          width: 360,
          backgroundColor: '#F5F5F5'
        },
        paperAnchorDockedLeft: {
          position: 'relative'
        }
      },
      MuiButton: {
        root: {
          borderRadius: 4,
          height: 48
        },
        label: {
          color: 'inherit',
          fontSize: 14,
          fontWeight: 600
        },
        contained: {
          width: '100%',
          boxShadow: 'none',
          '&.Mui-disabled': {
            '& .MuiButton-label': {
              opacity: 0.3
            }
          },
          '& .MuiCircularProgress-colorPrimary': {
            color: '#000000'
          }
        },
        sizeSmall: {
          height: 32
        },
        sizeLarge: {
          height: 64
        },
        containedPrimary: {
          '& .MuiButton-label': {
            color: '#000000'
          }
        }
      },
      MuiDivider: {
        light: {
          backgroundColor: '#ffffff'
        }
      },
      MuiTableCell: {
        head: {
          fontStyle: 'normal',
          fontSize: 10,
          lineHeight: '16px',
          textTransform: 'uppercase',
          letterSpacing: '0.08em',
          fontWeight: 600
        },
        body: {
          fontStyle: 'normal',
          fontSize: 14,
          lineHeight: '24px',
          fontWeight: 400
        }
      },
      MuiListItem: {
        root: {
          paddingTop: 10,
          paddingBottom: 10,
          '&$selected': {
            backgroundColor: '#515151',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#5f5d5d'
            },
            '& .MuiListItemIcon-root': {
              color: '#ffffff'
            }
          }
        }
      },
      MuiRadio: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: '1rem'
          }
        }
      },
      MuiOutlinedInput: {
        input: {
          padding: '0 12px',
          minHeight: 48
        }
      },
      MuiFormControl: {
        root: {
          '& .MuiTypography-subtitle2': {
            marginBottom: '4px'
          }
        }
      },
      MuiFormHelperText: {
        root: {
          lineHeight: '20px',
          color: '#000000',
          '&.Mui-disabled': {
            color: '#000000'
          }
        }
      },
      MuiInputBase: {
        root: {
          backgroundColor: '#ffffff',
          '&.Mui-error .MuiInputBase-input': {
            color: errorColor
          }
        },
        input: {
          height: 48,
          padding: '0 15px'
        }
      },
      MuiSelect: {
        selectMenu: {
          padding: '0 14px',
          minHeight: 48
        },
        select: {
          display: 'flex',
          alignItems: 'center'
        }
      },
      MuiFormLabel: {
        root: {
          lineHeight: '7px'
        }
      },
      MuiListItemIcon: {
        root: {
          justifyContent: 'center',
          minWidth: 40
        }
      },
      MuiListItemAvatar: {
        root: {
          minWidth: 32
        }
      },
      MuiAvatar: {
        square: {
          width: 24,
          height: 24
        }
      },
      MuiAlert: {
        message: {
          flex: 1
        },
        filledSuccess: {
          backgroundColor: '#499D71'
        }
      },
      MuiTabs: {
        root: {
          minHeight: 32,
          borderBottom: 'solid 1px #878787',
          '& .MuiTabScrollButton-root': {
            width: 24
          },
          '& .MuiTab-root': {
            minWidth: 'initial',
            minHeight: 32,
            padding: '0 16px 4px'
          },
          '& .MuiTabs-indicator': {
            height: 4
          }
        }
      },
      MuiFormControlLabel: {
        root: {
          marginRight: 0
        },
        label: {
          '&.MuiTypography-body1': {
            flex: 1
          }
        }
      },
      MuiPickersBasePicker: {
        container: {
          alignItems: 'center'
        }
      },
      MuiPickersCalendar: {
        week: {
          '& .MuiPickersDay-current': {
            color: '#499D71',
            backgroundColor: 'white',
            border: '1px solid'
          },
          '& .MuiPickersDay-day': {
            color: '#499D71',
            backgroundColor: 'white',
            border: '1px solid'
          },
          '& .MuiPickersDay-dayDisabled': {
            color: 'rgb(170, 170, 170)',
            fontWeight: 'inherit',
            backgroundColor: 'inherit',
            border: 'none'
          },
          '& .MuiPickersAvailableDate .MuiButtonBase-root': {
            color: '#ffffff',
            fontWeight: 700,
            backgroundColor: '#65987C',
            border: '1px solid #65987C'
          }
        }
      },
      MuiPickersCalendarHeader: {
        iconButton: {
          '&:not(.Mui-disabled) .MuiIconButton-label': {
            background: '#499D71',
            color: 'white',
            borderRadius: '50%'
          }
        }
      },
      MuiPopover: {
        root: {
          zIndex: '999999 !important',
          '& .MuiMenuItem-root': {
            minHeight: '48px !important'
          },
          '& .MuiListItem-root.Mui-selected': {
            backgroundColor: 'transparent',
            color: '#8DC63F',
            paddingLeft: 24
          }
        }
      },
      MuiTooltip: {
        tooltip: {
          color: '#ffffff',
          background: 'linear-gradient(77.73deg, #8DC63F 0%, #3D9459 100%)',
          fontStyle: 'normal',
          fontSize: 12,
          lineHeight: '16px',
          fontWeight: 400,
          padding: 16
        },
        arrow: {
          color: '#6BB14A'
        }
      },
      MuiCssBaseline: {
        '@global': {
          '@font-face': [...ExternalFonts],
          '*::-webkit-scrollbar': {
            width: '0.4em'
          },
          '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.4)',
            outline: '1px solid slategrey',
            borderRadius: 3
          },
          '#root': {
            margin: '0 auto',
            position: 'relative',
            maxWidth: 1440,
            minWidth: 320
          },
          a: {
            color: '#ffffff'
          }
        }
      }
    }
  })

export default createCustomTheme
