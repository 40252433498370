import { createTheme } from '@material-ui/core/styles'

export default function mixTheme(themeDefault, themeCustom) {
  const nTheme = { ...themeDefault }

  if (themeCustom) {
    nTheme.themeCustom = themeCustom
  }

  if (themeCustom?.COR_FUNDO) {
    nTheme.palette.background.default = themeCustom.COR_FUNDO
    nTheme.overrides.MuiDrawer.paper.backgroundColor = themeCustom.COR_FUNDO
  }

  if (themeCustom?.COR_PRIMARIA) {
    nTheme.palette.primary.main = themeCustom.COR_PRIMARIA

    nTheme.overrides.MuiButton = {
      ...themeDefault.overrides.MuiButton,
      ...{
        root: {
          ...themeDefault.overrides.MuiButton.root,
          ...{
            '&.MuiButton-containedPrimary': {
              color: `${themeCustom?.COR_CONTRASTE_PRIMARIA}`,
              '& .MuiButton-label': {
                color: 'inherit'
              }
            },
            '&.MuiButton-containedPrimary:hover': {
              backgroundColor: `${themeCustom?.COR_PRIMARIA}de`,
              color: `${themeCustom?.COR_CONTRASTE_PRIMARIA}`,
              '& .MuiButton-label': {
                color: 'inherit'
              }
            }
          }
        }
      }
    }

    nTheme.overrides.MuiFormLabel.root['&.Mui-error'] = {
      color: themeCustom?.COR_CONTEUDO_PRIMARIA || themeCustom.COR_PRIMARIA
    }

    nTheme.overrides.MuiCssBaseline['@global'] = {
      ...themeDefault.overrides.MuiCssBaseline['@global'],
      ...{
        'body, a': {
          color: themeCustom?.COR_CONTEUDO_PRIMARIA || themeCustom.COR_PRIMARIA
        }
      }
    }

    nTheme.overrides.MuiFormLabel.filled = {
      color: themeCustom?.COR_CONTEUDO_PRIMARIA || themeCustom.COR_PRIMARIA
    }

    nTheme.overrides.MuiTypography = {
      root: {
        '& a': {
          color: themeCustom?.COR_CONTEUDO_PRIMARIA || themeCustom.COR_PRIMARIA
        }
      }
    }

    nTheme.overrides.MuiCheckbox = {
      root: {
        color: themeCustom?.COR_CONTEUDO_PRIMARIA || themeCustom.COR_PRIMARIA
      }
    }

    nTheme.overrides.MuiRadio.root = {
      ...nTheme.overrides.MuiRadio.root,
      ...{
        color: themeCustom?.COR_CONTEUDO_PRIMARIA || themeCustom.COR_PRIMARIA
      }
    }
  }

  if (themeCustom?.COR_CONTEUDO_PRIMARIA) {
    nTheme.overrides.MuiTypography = {
      root: {
        color: themeCustom?.COR_CONTEUDO_PRIMARIA
      },
      colorPrimary: {
        color: themeCustom.COR_CONTEUDO_PRIMARIA
      }
    }
  }

  if (themeCustom?.COR_MSG_ERRO) {
    nTheme.palette.error.main = themeCustom.COR_MSG_ERRO

    nTheme.overrides.MuiAlert.filledError = {
      backgroundColor: themeCustom.COR_MSG_ERRO
    }

    nTheme.overrides.MuiInputBase.root['&.Mui-error .MuiInputBase-input'] = {
      color: themeCustom.COR_MSG_ERRO
    }

    nTheme.overrides.MuiInputBase.root['&.Mui-error .MuiOutlinedInput-notchedOutline'] = {
      borderColor: `${themeCustom.COR_MSG_ERRO} !important`
    }
  }

  if (themeCustom?.COR_CONTRASTE_MSG_ERRO) {
    nTheme.overrides.MuiAlert.filledError = {
      color: themeCustom.COR_CONTRASTE_MSG_ERRO
    }
  }

  if (themeCustom?.COR_TEXTO_MSG_ERRO) {
    nTheme.overrides.MuiFormHelperText.root['&.Mui-error'] = {
      color: themeCustom.COR_TEXTO_MSG_ERRO
    }
  }

  if (themeCustom?.COR_CONTRASTE_PRIMARIA) {
    nTheme.overrides.MuiInput = {
      underline: {
        '&:after': {
          borderBottomColor: themeCustom.COR_CONTRASTE_PRIMARIA
        }
      }
    }

    nTheme.overrides.MuiChip = {
      root: {
        borderRadius: 4,
        '&.Mui-disabled': {
          opacity: 1,
          backgroundColor: themeCustom.COR_CONTRASTE_PRIMARIA,
          color: themeCustom.COR_PRIMARIA
        }
      }
    }
  }

  if (themeCustom?.COR_SECUNDARIA) {
    nTheme.palette.secondary.main = themeCustom.COR_SECUNDARIA

    nTheme.overrides.MuiButton = {
      ...themeDefault.overrides.MuiButton,
      ...{
        root: {
          ...themeDefault.overrides.MuiButton.root,
          ...{
            '&.MuiButton-containedSecondary:hover': {
              backgroundColor: `${themeCustom?.COR_SECUNDARIA}de`,
              color: `${themeCustom?.COR_CONTRASTE_SECUNDARIA}`,
              '& .MuiButton-label': {
                color: 'inherit'
              }
            }
          }
        }
      }
    }
  }

  if (themeCustom?.COR_TERCIARIA) {
    nTheme.palette['brand-tertiary'].main = themeCustom.COR_TERCIARIA

    nTheme.overrides.MuiTypography.colorPrimary = {
      ...nTheme.overrides.MuiTypography.colorPrimary,
      ...{
        '& span': {
          color: themeCustom.COR_TERCIARIA
        }
      }
    }
  }

  if (themeCustom?.COR_DESABILITADA_PRIMARIA) {
    nTheme.overrides.MuiButton = {
      ...themeDefault.overrides.MuiButton,
      ...{
        root: {
          ...themeDefault.overrides.MuiButton.root,
          ...{
            '&.Mui-disabled': {
              backgroundColor: `${themeCustom?.COR_DESABILITADA_PRIMARIA} !important`,
              color: `${themeCustom?.COR_DESABILITADA_CONTRASTE_PRIMARIA} !important`,
              '& .MuiButton-label': {
                color: 'inherit'
              }
            }
          }
        }
      }
    }
  }

  return createTheme(nTheme)
}
