import { useSelector } from 'react-redux'
import sha1 from 'crypto-js/sha1'
import TagManager from 'react-gtm-module'
import { isEmbedMode } from '~/helpers/contentMode'

const useTracking = () => {
  const { campaignId } = useSelector((state) => state.campaignReducers.campaign)
  const { consumerCpf } = useSelector((state) => state.voucherReducers.voucher)
  const contentMode = useSelector((state) => state.configReducers.contentMode)

  const track = ({ event, parameter, value }) => {
    if (isEmbedMode(contentMode)) return

    const objData = {
      ...(value ? { valor: Array.isArray(value) ? value.join(',') : value } : {}),
      ...(parameter ? { parametro: parameter } : {}),
      ...(campaignId ? { campanha: campaignId } : {}),
      ...(consumerCpf ? { hash_cpf: sha1(consumerCpf).toString() } : {})
    }

    TagManager.dataLayer({
      dataLayer: {
        event: `e_${event}`,
        ...objData
      }
    })

    if (typeof window?.Native?.genericEvent === 'function') {
      window.Native.genericEvent(`e_${event}`, objData)
    }
  }

  return track
}

export default useTracking
