export function getQuiz({ campaignId, consumerCpf } = {}) {
  return {
    type: 'quiz/GET_QUIZ',
    payload: { campaignId, consumerCpf }
  }
}

export function updateQuiz({ quiz, error } = {}) {
  return {
    type: 'quiz/UPDATE_QUIZ',
    payload: { quiz, error }
  }
}

export function setQuiz({ campaignId, consumerCpf, questionCode, answers } = {}) {
  return {
    type: 'quiz/SET_QUIZ',
    payload: { campaignId, consumerCpf, questionCode, answers }
  }
}

export function setQuizStep({ quizStep } = {}) {
  return {
    type: 'quiz/SET_QUIZ_STEP',
    payload: { quizStep }
  }
}

export function resetQuiz() {
  return {
    type: 'quiz/RESET_QUIZ'
  }
}

export function setQuizResult({ quizResult } = {}) {
  return {
    type: 'quiz/SET_QUIZ_RESULT',
    payload: { quizResult }
  }
}

export function setPreviousQuizSteps({ previousQuizSteps } = {}) {
  return {
    type: 'quiz/SET_PREVIOUS_QUIZ_STEP',
    payload: { previousQuizSteps }
  }
}
