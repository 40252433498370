import produce from 'immer'

import { Types } from './actions'

const INITIAL_STATE = {
  loadings: {
    getBonusOffers: false
  },
  errors: {
    getBonusOffers: false
  },
  attempts: {
    getBonusOffers: 0
  },
  state: '',
  city: '',
  bonusOffers: [],
  filteredOffers: [],
  filters: {
    category: [],
    offerType: [],
    channel: [],
    partnerName: [],
    kind: []
  },
  query: ''
}

export default function bonusOffersReducers(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.getBonusOffers: {
        draft.loadings.getBonusOffers = true
        draft.errors.getBonusOffers = false
        draft.attempts.getBonusOffers = 0
        draft.state = action.payload?.state || ''
        draft.city = action.payload?.city || ''
        break
      }
      case Types.updateBonusOffers: {
        draft.loadings.getBonusOffers = false
        draft.errors.getBonusOffers = action.payload?.error || false
        draft.attempts.getBonusOffers = 1

        if (action.payload?.bonusOffers && !action.payload?.error) {
          draft.bonusOffers = action.payload.bonusOffers
        }
        break
      }
      case Types.updateFilteredOffers: {
        draft.filteredOffers = action.payload.filteredOffers || []
        break
      }
      case Types.updateFilters: {
        draft.filters = action.payload.filters || INITIAL_STATE.filters
        break
      }
      case Types.updateQuery: {
        draft.query = action.payload.query || INITIAL_STATE.query
        break
      }
      default:
    }
  })
}
