import React from 'react'
import { useParams } from 'react-router-dom'

import Dynamic from '~/pages/dynamic'

const DynamicRoute = () => {
  const { campaignId, slug } = useParams()

  const [Component, step] = Dynamic({ campaignId, slug })

  return <Component step={step} />
}

export default DynamicRoute
