import produce from 'immer'

import * as ACTs from './actions'

export const Types = {}

Object.values(ACTs).forEach((fn) => {
  const type = fn().type

  Types[type.split('/').pop()] = type
})

const INITIAL_STATE = {
  loadings: {
    searchResellersNearby: false,
    bookingVoucher: false
  },
  errors: {
    searchResellersNearby: false,
    bookingVoucher: false
  },
  resellers: [],
  reseller: {}
}

export default function resellerReducers(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.SEARCH_RESELLERS_NEARBY: {
        draft.loadings.searchResellersNearby = true
        draft.errors.searchResellersNearby = false
        break
      }
      case Types.UPDATE_RESELLERS_NEARBY: {
        draft.loadings.searchResellersNearby = false
        draft.errors.searchResellersNearby = action.payload?.error || false

        draft.resellers = action.payload?.resellers || []
        break
      }
      case Types.PICK_RESELLER: {
        if (action.payload?.reseller?.resellerCellPhone) {
          draft.reseller = { ...state.reseller, ...action.payload.reseller }
        } else {
          draft.reseller = action.payload?.reseller || {}
        }
        break
      }
      case Types.BOOKING_VOUCHER: {
        draft.loadings.bookingVoucher = true
        draft.errors.bookingVoucher = false
        break
      }
      case Types.UPDATE_BOOKING_VOUCHER: {
        draft.loadings.bookingVoucher = false
        draft.errors.bookingVoucher = action.payload?.error || false

        if (action.payload?.reseller) {
          draft.reseller = { ...state.reseller, ...action.payload?.reseller }
        }
        break
      }
      default:
    }
  })
}
