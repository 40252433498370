import { takeLatest, put, all, call } from 'redux-saga/effects'
import { toLocation } from '~/helpers/redirect'

import api from '~/services/api'
import newrelic from '~/services/newrelic'

import { updateVoucherVerify, updateVoucher, updateChannel, updateEligibleConsumer } from './actions'

export function* getVoucher({ payload }) {
  let response

  try {
    const { campaignId, consumerCpf, consumerBirthday, captchaToken, isReadOnly, comingPath } = payload

    if (!campaignId || !consumerCpf) throw new Error('Ocorreu um erro ao processar a requisição')

    const url = `/campaign/${campaignId}/consumer/${consumerCpf}`

    response = yield call(api.get, url, {
      params: {
        ...(consumerBirthday
          ? {
              consumerBirthday: consumerBirthday.split('/').reverse().join('-')
            }
          : {}),
        ...(captchaToken ? { captchaToken } : {}),
        readOnly: isReadOnly
      }
    })

    if (response.status === 200) {
      if (response.data?.data && response.data.data?.length > 0) {
        const { accessToken, refreshToken, eligibleConsumer, ...voucher } = response.data.data[0]
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('refreshToken', refreshToken)

        voucher.comingPath = comingPath
        voucher.dataCashback = response.data?.data[0]

        yield put(updateEligibleConsumer({ eligibleConsumer }))

        if (response.data.success && eligibleConsumer) {
          yield put(updateVoucherVerify({ voucher }))
        } else {
          const treatErrorAPI = response.data.message.includes('faltantes no cadastro')
            ? response.data.data
            : response?.data?.errors[0]
          yield put(
            updateVoucherVerify({
              error: treatErrorAPI || response.data.message
            })
          )
        }
      } else if (!response.data.success) {
        throw new Error(response?.data?.errors[0] || response.data.message)
      }
    } else {
      throw new Error('Ocorreu um erro ao processar a requisição')
    }
  } catch (error) {
    newrelic.noticeError(error, { response: response?.data, payload })
    yield put(updateVoucherVerify({ error: error.message }))
  }
}

export function* setVoucher({ payload }) {
  const { campaignId, consumer, captchaToken, step = 'CADASTRO', _redirect, _condition } = payload
  let response

  try {
    if (!campaignId || !consumer) throw new Error('Ocorreu um erro ao processar a requisição')

    const url = `/campaign/${campaignId}/consumer?step=${step}`

    response = yield call(api.post, url, consumer, {
      params: {
        ...(captchaToken ? { captchaToken } : {})
      }
    })

    if (response.status === 200) {
      if (response.data.success && response.data?.data && response.data.data.length > 0) {
        yield put(updateVoucher({ voucher: response.data.data[0] }))
        yield toLocation(_redirect, _condition, response.data?.data)
      } else {
        throw new Error(response?.data?.errors[0] || response.data.message)
      }
    } else {
      throw new Error('Ocorreu um erro ao processar a requisição')
    }
  } catch (error) {
    newrelic.noticeError(error, { response: response?.data, payload })
    yield put(updateVoucher({ error: error.message }))
  }
}

export function* setChannel({ payload }) {
  try {
    const { campaignId, consumerCpf, channelType, _redirect, _condition } = payload

    if (!campaignId || !consumerCpf || !channelType) throw new Error('Ocorreu um erro ao processar a requisição')

    const url = `/campaign/${campaignId}/consumer/${consumerCpf}/channel/${channelType}`

    const response = yield call(api.post, url)

    if (response.status === 200) {
      if (response.data.success && response.data?.data && response.data.data.length > 0) {
        yield put(updateChannel({ channel: response.data.data[0] }))
        yield toLocation(_redirect, _condition, response.data?.data)
      } else {
        yield put(
          updateChannel({
            error: response?.data?.errors[0] || response.data.message
          })
        )
      }
    } else {
      yield put(updateChannel({ error: 'Ocorreu um erro ao processar a requisição' }))
    }
  } catch (error) {
    yield put(updateChannel({ error: error.message }))
  }
}

export default all([
  takeLatest('voucher/GET_VOUCHER', getVoucher),
  takeLatest('voucher/SET_VOUCHER', setVoucher),
  takeLatest('voucher/SET_CHANNEL', setChannel)
])
