import configs from '~/env.json'
import mixTheme, * as Themes from '~/assets/styles'
import { boticarioTheme, quemDisseBereniceTheme, vultTheme, gbInstitucionalTheme } from '@grupoboticario/flora'

const defaultBranch = configs?.REACT_APP_BRANCH || 'BOT'

const floraThemes = {
  BOT: boticarioTheme,
  QDB: quemDisseBereniceTheme,
  VULT: vultTheme,
  DEFAULT: gbInstitucionalTheme
}

export function getMuiThemeOptions(branch, previewLayoutCampaign, layout) {
  const selectedBranch = branch || defaultBranch
  const themeSource = previewLayoutCampaign || layout || Themes.defaultLayout(defaultBranch)
  return mixTheme(Themes[selectedBranch], themeSource)
}

export function getFloraThemeOptions(branch) {
  const selectedBranch = branch || defaultBranch
  return floraThemes[selectedBranch] || floraThemes.BOT
}

export function getThemeForMenuItems(branch) {
  return floraThemes[branch] || floraThemes.DEFAULT
}
