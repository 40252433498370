import { useEffect, useState } from 'react'

const CANONICAL_CONFIG = {
  urlIdentifier: '/botirecicla',
  canonicalUrl: 'https://boti-recicla.boticario.com.br'
}

const insertCanonicalLink = () => {
  const link = createCanonicalLink()
  const position = findInsertPosition()

  position ? position.parentNode.insertBefore(link, position.nextSibling) : document.head.appendChild(link)
}

const createCanonicalLink = () => {
  const link = document.createElement('link')
  link.rel = 'canonical'
  link.href = CANONICAL_CONFIG.canonicalUrl
  return link
}

const findInsertPosition = () => {
  const linkTags = document.head.getElementsByTagName('link')
  return linkTags.length > 0 ? linkTags[linkTags.length - 1] : null
}

const removeCanonicalLink = () => {
  const canonical = document.querySelector('link[rel="canonical"]')
  canonical?.remove()
}

export function useCanonicalLink() {
  const [pathname, setPathname] = useState(window.location.pathname)

  useEffect(() => {
    const handlePathChange = () => setPathname(window.location.pathname)
    window.addEventListener('popstate', handlePathChange)

    const manageCanonicalLink = () => {
      const shouldShowCanonical = pathname.includes(CANONICAL_CONFIG.urlIdentifier)
      const existingCanonical = document.querySelector('link[rel="canonical"]')

      if (shouldShowCanonical && !existingCanonical) {
        insertCanonicalLink()
      }

      if (!shouldShowCanonical && existingCanonical) {
        existingCanonical.remove()
      }
    }

    manageCanonicalLink()

    return () => {
      window.removeEventListener('popstate', handlePathChange)
      removeCanonicalLink()
    }
  }, [pathname])
}
