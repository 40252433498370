import { takeLatest, put, all, call } from 'redux-saga/effects'

import api from '~/services/api'

import { updateQuiz } from './actions'

export function* getQuiz({ payload }) {
  try {
    const { campaignId, consumerCpf } = payload

    if (!campaignId || !consumerCpf) throw new Error('Ocorreu um erro ao processar a requisição')

    const url = `/campaign/${campaignId}/quiz?consumerCpf=${consumerCpf}`

    const response = yield call(api.get, url)

    if (response.status === 200) {
      if (response.data.success && response.data?.data && response.data.data.length > 0) {
        yield put(updateQuiz({ quiz: response.data.data[0] }))
      } else {
        yield put(
          updateQuiz({
            error: response?.data?.errors[0] || response.data.message
          })
        )
      }
    } else {
      yield put(updateQuiz({ error: 'Ocorreu um erro ao processar a requisição' }))
    }
  } catch (error) {
    yield put(updateQuiz({ error: error.message }))
  }
}

export function* setQuiz({ payload }) {
  try {
    const { campaignId, consumerCpf, questionCode, answers } = payload

    if (!campaignId || !consumerCpf || (parseInt(questionCode) > 0 && (!answers || answers.length === 0)))
      throw new Error('Ocorreu um erro ao processar a requisição')

    const url = `/campaign/${campaignId}/quiz`

    const response = yield call(api.post, url, {
      consumerCpf,
      questionCode,
      answers
    })

    if (response.status === 200) {
      if (response.data.success && response.data?.data && response.data.data.length > 0) {
        yield put(updateQuiz({ quiz: response.data.data[0] }))
      } else {
        yield put(
          updateQuiz({
            error: response?.data?.errors[0] || response.data.message
          })
        )
      }
    } else {
      yield put(updateQuiz({ error: 'Ocorreu um erro ao processar a requisição' }))
    }
  } catch (error) {
    yield put(updateQuiz({ error: error.message }))
  }
}

export default all([takeLatest('quiz/GET_QUIZ', getQuiz), takeLatest('quiz/SET_QUIZ', setQuiz)])
