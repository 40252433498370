import configs from '~/env.json'
import produce from 'immer'

import * as ACTs from './actions'
import { defaultLayout } from '~/assets/styles'

export const Types = {}

Object.values(ACTs).forEach((fn) => {
  const type = fn().type

  Types[type.split('/').pop()] = type
})

const INITIAL_STATE = {
  loadings: {
    getCampaignConfig: false
  },
  errors: {
    getCampaignConfig: false
  },
  attempts: {
    getCampaignConfig: 0
  },
  campaign: {},
  appAccess: false,
  blockFront: false
}

export default function campaignReducers(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.GET_CAMPAIGN_CONFIG: {
        draft.loadings.getCampaignConfig = true
        draft.errors.getCampaignConfig = false
        draft.appAccess = action.payload?.appAccess || false
        break
      }
      case Types.UPDATE_CAMPAIGN_CONFIG: {
        draft.loadings.getCampaignConfig = false
        draft.errors.getCampaignConfig = action.payload?.error || false
        draft.attempts.getCampaignConfig = 1

        if (action?.payload?.campaign) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { appExclusivity, ...campaign } = action?.payload?.campaign

          if (campaign?.branch === configs?.REACT_APP_BRANCH || configs?.NODE_ENV !== 'production') {
            draft.campaign = campaign

            const layout = campaign.layout
            draft.campaign.layout = {
              ...defaultLayout(campaign?.branch),
              ...layout
            }

            draft.blockFront = !!appExclusivity
          } else {
            draft.campaign = { branch: configs?.REACT_APP_BRANCH || 'BOT' }
          }
        }
        break
      }
      default:
    }
  })
}
