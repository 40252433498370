import { takeLatest, put, all, call } from 'redux-saga/effects'

import api from '~/services/api'

import { updateResellersNearby, updateBookingVoucher, pickReseller } from './actions'

import { updateVoucher } from '~/store/modules/voucher/actions'

export function* searchResellersNearby({ payload }) {
  try {
    const { campaignId, geolocation } = payload
    const { lat, lng } = geolocation

    if (!campaignId || !lat || !lng) throw new Error('Ocorreu um erro ao processar a requisição')

    const urlParams = new URLSearchParams()

    urlParams.append('lat', lat)
    urlParams.append('lng', lng)

    const url = `/campaign/${campaignId}/resellers/nearby?${urlParams.toString()}`

    const response = yield call(api.get, url)

    if (response.status === 200) {
      if (!response?.data?.errors || response.data.errors.length === 0) {
        yield put(updateResellersNearby({ resellers: response.data.data }))
      } else {
        yield put(
          updateResellersNearby({
            error: response?.data?.errors[0] || response.data.message
          })
        )
      }
    } else {
      yield put(
        updateResellersNearby({
          error: 'Ocorreu um erro ao processar a requisição'
        })
      )
    }
  } catch (error) {
    yield put(updateResellersNearby({ error: error.message }))
  }
}

export function* bookingVoucher({ payload }) {
  try {
    const { campaignId, consumerCpf, resellerCode, voucherCode } = payload

    if (!campaignId || !consumerCpf || !resellerCode || !voucherCode)
      throw new Error('Ocorreu um erro ao processar a requisição')

    const url = `/campaign/${campaignId}/consumer/${consumerCpf}/booking`

    const response = yield call(api.post, url, {
      voucherCode,
      resellerCode
    })

    if (response.status === 200) {
      if (response.data.success && response.data?.data && response.data.data.length > 0) {
        const { resellerCellPhone, resellerCode, ...voucher } = response.data.data[0]

        yield put(updateVoucher({ voucher }))
        yield put(pickReseller({ reseller: { resellerCellPhone, resellerCode } }))
      } else {
        yield put(
          updateBookingVoucher({
            error: response?.data?.errors ? response?.data?.errors[0] : response.data.message
          })
        )
      }
    } else {
      yield put(
        updateBookingVoucher({
          error: 'Ocorreu um erro ao processar a requisição'
        })
      )
    }
  } catch (error) {
    yield put(updateBookingVoucher({ error: error.message }))
  }
}

export default all([
  takeLatest('reseller/SEARCH_RESELLERS_NEARBY', searchResellersNearby),
  takeLatest('reseller/BOOKING_VOUCHER', bookingVoucher)
])
