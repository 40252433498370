export default {
  // general layouts: /
  'Aguarde ...': 'Aguarde ...',
  'O layout não foi encontrado': 'O layout não foi encontrado',

  // page: /error-campaign
  'Ocorreu um erro': 'Ocorreu um erro',
  'A campanha não foi encontrada ou não está mais ativa': 'A campanha não foi encontrada ou não está mais ativa',

  // menu
  'Página Inicial': 'Página Inicial',
  'Consulte seu Cupom': 'Consulte seu Cupom',
  'Conheça a linha Cuide-se bem': 'Conheça a linha Cuide-se bem',
  Regulamento: 'Regulamento',
  FAQ: 'FAQ',
  'Política de Privacidade': 'Política de Privacidade',

  // page: /show_voucher
  'Olá {revendedora}, tudo bem? Eu sou {consumidor}, acabei de fazer a reserva de um {prm}, e gostaria de combinar a retirada contigo entre {dataInicio} e {dataFim}. Podemos combinar a retirada?':
    'Olá {revendedora}, tudo bem? Eu sou {consumidor}, acabei de fazer a reserva de um {prm}, e gostaria de combinar a retirada contigo entre {dataInicio} e {dataFim}. Podemos combinar a retirada?',
  'Este cupom é válido a partir de {dataInicio} até dia {dataFim}.':
    'Este cupom é válido a partir de {dataInicio} até dia {dataFim}.',
  'Combine a retirada com a Revendedora a partir do dia {dataInicio}.':
    'Combine a retirada com a Revendedora a partir do dia {dataInicio}.',
  Ligar: 'Ligar',
  Whatsapp: 'Whatsapp',
  Fechar: 'Fechar',

  // page: /escolhe_data_resgate
  'Esse dia não pode ser alterado pois já existem reservas nele.':
    'Esse dia não pode ser alterado pois já existem reservas nele.',
  'Você já desmarcou a quantidade máxima de dias possíveis para esta ação':
    'Você já desmarcou a quantidade máxima de dias possíveis para esta ação'
}
